import React from "react"
import styled from "styled-components"
import SubscribeForm from "../common/mailchimp";

import { Container, Section } from "../global"

const GetStarted = () => (
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>
        Subscribe to our newsletter now and we'll let you know when we start in the U.S. at the end of December.
      </GetStartedTitle>
      <SubscribeForm/>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
  
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
  max-width: 760px;
`

const GetStartedTitle = styled.h4`
  max-width: 960px;
  padding: 20px;
  margin: 0 auto 32px;
  text-align: center;
`
