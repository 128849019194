import React, { useState } from "react"
import Modal from 'react-modal'
import styled from "styled-components"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxWidth: '400px',
  }
};

const SubscribeForm = () => {

  const [email, setEmail] = useState('');
  const [modalIsOpen,setIsOpen] = React.useState(false);

  const handleInputChange = event => {
    setEmail(event.currentTarget.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await addToMailchimp(email);
    setIsOpen(true);
  }
  
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeDialog(){
    setIsOpen(false);
  }

  return (
    <Container>
      <HeaderForm onSubmit={handleSubmit}>
        <HeaderInput name="email" placeholder="Your Email"
            type="email" onChange={handleInputChange} />
        <HeaderButton>Subscribe</HeaderButton>
      </HeaderForm>
      <MailchimpDescription>
        We will not send you any advertising or sell your email address.
        You will receive an email once or twice a month
        from us, with the latest news, developments, and plans at apocha.
        Of course, you can also unsubscribe at any time.
      </MailchimpDescription>

      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeDialog}
          style={customStyles}
        >

          <h5>Thank you!</h5>
          <p>
            Thank you for subscribing to apocha's newsletter. You will receive an email shortly,
            in which you can confirm the registration again. From now on, you will always stay up to date
            about what's going on at apocha.
          </p>
          <p>&nbsp;</p>
          <CloseButton onClick={closeDialog}>OK</CloseButton>
        </Modal>
    </Container>
  )
}

export default SubscribeForm

const Container = styled.div`
  padding: 0 40px;
  max-width: 760px;
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin: 0px 0px 20px 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const MailchimpDescription = styled.div`
  font-size: small;
`

const CloseButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 30px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
